.dropdown {
  position: relative;
  z-index: 1;

  &__content {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    min-width: 160px;
    font-size: 14px;
    text-align: left;
    list-style: none;
    display: none;

    &--right {
      left: auto;
      right: 0;
    }

    &--left {
      left: 0;
      right: auto;
    }

    &--top {
      bottom: 100%;
      top: auto;
      margin-bottom: .5rem;
      margin-top: 0;
    }

    @media screen and (max-width: 768px) {
      width: 100%;

      &--mobile-left {
        left: 0;
        right: 0;
      }
    }
  }

  &__toggle {
    cursor: pointer;

    &--carret {
      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .5rem;
        vertical-align: middle;
        border-top: .5rem dashed;
        border-right: .5rem solid transparent;
        border-left: .5rem solid transparent;
      }
    }

    &--disabled {
      pointer-events: none;
      opacity: .2;
    }
  }

  &--open {
    z-index: 2;

    .dropdown {
      &__content {
        display: flex;
      }

      &__toggle {
        &--carret {
          &:after {
            border-top: 0;
            border-bottom: .5rem dashed;
            border-right: .5rem solid transparent;
            border-left: .5rem solid transparent;
          }
        }
      }
    }
  }
}

.dropdown-filters {
  z-index: unset;

  .dropdown__content {
    width: 100%;

    form {
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    max-height: 25rem;
    overflow: auto;
    background: #fff;
    border: none;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    margin-top: 1rem;
  }

  &__link {
    font-size: 1.2rem;
    padding: 1rem;
    color: @light-black;
    display: block;
    position: relative;

    &:before {
      content: '\f111';
      font-family: 'font awesome 5 pro';
      font-weight: 400;
      margin-right: 1rem;
    }

    &--selected {
      background: @yellow;
    }

  }
}

.dropdown-download {
  &__content {
    width: 100%;
    max-height: 25rem;
    overflow: auto;
    background: #fff;
    border: none;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    margin-top: 1rem;
  }

  &__link {
    font-size: 1.2rem;
    padding: 1rem;
    color: @light-black;
    display: block;
    position: relative;

    &:before {
      content: @fa-var-download;
      font-family: 'font awesome 5 pro';
      font-weight: 400;
      margin-right: 1rem;
    }
  }
}

.dropdown-user {
  &__content {
    background: @darker-gray;
    border-radius: 1rem;
    padding: .5rem 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  }

  &__link {
    color: white;
    display: grid;
    padding: .5rem 1.5rem;
    grid-template-columns: 3rem 1fr;
    align-items: center;

    i {
      position: relative;
      transition: all ease 250ms;
      right: 0;
    }

    &:hover {
      color: var(--main-color);

      i {
        right: -.25rem;
      }
    }
  }

  @media screen and (max-width: 920px) {
    display: contents;

    .dropdown__content {
      display: contents !important;
    }

    &__content {

      background: transparent;
      padding: 0;
      box-shadow: none;
      width: 100%;
      border-top: 1px solid @darker-gray;
      border-radius: 0;
      margin-top: 2rem;
      padding-top: 2rem;
    }

    &__link {
      color: white;
      display: block;
      font-size: 1.6rem;
      letter-spacing: 1px;
      font-weight: 300;
      position: relative;
      width: 100%;
      padding: 0.5rem 1rem;

      i {
        align-items: center;
        justify-content: center;
        border: 1px solid @darker-gray;
        height: 4rem;
        width: 4rem;
        border-radius: 4rem;
        margin-right: 2rem;
        display: inline-flex;
      }
    }
  }
}
