.cta {
  background: transparent;
  border: none;
  padding: 1rem;
  font-size: 1.5rem;
  display: block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;

  &--gold {
    background: linear-gradient(45deg, #b36b03, #d2a134, #f1d557, #d9ad3c, #b16700);
    color: black;
    font-weight: bold;
  }

  &--big {
    padding: 1rem 2rem;
  }

  &__icon {
    margin-right: 1rem;
  }

  &--main-color {
    background: linear-gradient(45deg, #b36b03, #d2a134, #f1d557, #d9ad3c, #b16700);
    color: black;
    font-weight: bold;
  }

  &--gray {
    background-color: @light-gray;
    color: white;
  }

  &--background-green {
    background: @green;
    color: white;
    border: 2px solid @green;
  }

  &--border-green {
    border: 2px solid @green;
    color: @green;

    small {
      color: white;
    }
  }

  > * {
    pointer-events: none;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }

  i {
    margin-right: 1rem;
  }

  small {
    display: block;
    font-weight: normal;
  }
}
