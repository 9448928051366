.content-list {
  &__header {
    display: flex;
    align-items: baseline;
    margin-bottom: 3rem;

    &--split {
      justify-content: space-between;
    }

    &__left {
      display: flex;
      align-items: baseline;
    }
  }

  &__title {
    margin: 0;
    font-size: 3rem;
    letter-spacing: 1px;
    color: white;
    position: relative;
    padding: 0 2rem;
    font-weight: 500;

    &:before {
      content: '';
      height: 100%;
      width: .2rem;
      background-color: @yellow;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__see-more {
    color: var(--main-color);
    font-size: 1.8rem;
    padding: 0;
    font-weight: 400;
  }

  &__custom-layout {
    display: grid;
    grid-template-areas: "A A B B B B"
                         "A A B B B B";

    .gl__col {
      &:nth-child(1) {
        grid-area: A;

        .video-item {
          padding-bottom: calc((390 / 280) * 100% + 1.5rem);
        }
      }
    }
  }

  &__empty {
    text-align: center;
    font-weight: bold;
  }

  @media screen and (max-width: 920px) {
    &__header {
      flex-wrap: wrap;
      justify-content: center;

      &__left {
        a {
          display: none;
        }
      }
    }

    &__title {
      font-size: 2rem;
      width: 100%;
      text-align: center;

      &:before {
        display: none;
      }
    }

    &__see-more {
      text-align: center;
      border-bottom: 1px solid var(--main-color);
      margin: 0 auto;
      padding: 0.25rem 2rem;
      font-size: 1.5rem;
    }

    &__custom-layout {
      grid-template-areas: unset;
      grid-template-columns: 1fr 1fr;

      .gl__col {
        &:nth-child(1) {
          grid-area: unset;

          .video-item {
            padding-bottom: calc((390 / 280) * 100%);
          }
        }

        &:last-child {
          grid-column-end: 3 !important;
          grid-column-start: 1 !important;
        }
      }

      .video-item {
        &--last {
          padding-bottom: calc((390 / 280) * 25%);
          background: linear-gradient(45deg, #b36b03, #d2a134, #f1d557, #d9ad3c, #b16700);
          color: black;

          .video-item {
            &__thumb {
              object-fit: cover;
              object-position: center;
              filter: blur(5px) grayscale(1);
              opacity: 0.3;
              transform: scale(1.2);
            }

            &__see-more {
              color: black;
            }
          }
        }
      }
    }
  }

  &__tab {
    display: none;

    &--active {
      display: block;
    }

  }
}

.related-content {
  display: flex;
  flex-wrap: wrap;

  &__link {
    display: block;
    margin: .25rem .25rem;
    cursor: pointer;
    background-color: var(--main-color);
    padding: 1rem 2rem;
    color: black;
    font-weight: bold;
    font-size: 1.5rem;
    transition: all ease 250ms;

    &:hover {
      .scale(1.05);
    }
  }
}
