.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: 1rem;
  margin-right: 1rem;

  &--desktop {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  &--mobile {
    display: none;
  }

  &__item {
    margin-right: 2px;
    margin-left: 2px;
    display: flex;
    align-items: center;
  }

  &__btn {
    position: relative;
    color: white;
    text-decoration: none;
    background-color: #424242;
    border-radius: 0.5rem;
    border: 0;
    width: 40px;
    height: 40px;
    display: flex;
    place-items: center;
    text-align: center;
    justify-content: center;

    &:hover, &:active, &:focus {
      background-color: #575757;
    }

    &--nav,
    &--input {
      background-color: var(--main-color);
      color: white;

      &:hover, &:active, &:focus {
        background-color: var(--dark-main-color);
        color: white;
      }
    }
  }

  &__separator {
    cursor: default;
    position: relative;
    color: @dark-gray;
    background: transparent;
  }
}

@media screen and (max-width: 768px) {
  .pagination {
    &--desktop {
      margin-bottom: 1rem;
    }

    &--mobile {
      display: flex;
      margin-bottom: 2rem;

      .pagination {
        &__btn {
          background-color: transparent;
          width: 20px;
          height: 20px;
        }
      }
    }

    &__item {
      &--ghost {
        display: none;
      }
    }

    &__btn {
      width: 35px;
      height: 35px;
    }
  }
}
