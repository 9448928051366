.get-premium {
  padding: 1rem 0;
  position: relative;

  &__content {
    display: block;
    padding-top: calc(149 / 978 * 100%);
    width: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    background: transparent;
  }

  &__link {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
  }

  &__img {
    display: block;
    width: 100%;
    object-fit: contain;
  }

  > a {
    width: 100%;
    display: block;

    > img {
      display: block;
      width: 100%;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 920px) {
    &__content {
      padding-top: calc(290 / 708 * 100%);

      &--partners {
        padding-top: calc(450 / 400 * 100%);
      }
    }

    &__offer {
      padding: 0 1rem 1rem 1rem;
    }
  }
}

.premium-offer-wrapper {
  background: #111;
  margin-bottom: 3rem;
  margin-top: -3rem
}


.contact-link {
  padding: 1rem;
  color: white;
  text-align: center;
  position: relative;
  z-index: 2;

  a {
    color: var(--main-color);
  }
}
