.header {
  position: relative;

  &__rencontre {
    padding: 14px 18Px;
    display: block;
    background: #232323;
    border-radius: 3rem;
    position: relative;

    &:before {
      content: attr(data-notification);
      position: absolute;
      top: 0;
      right: 0;
      color: white;
      border-radius: 50%;
      aspect-ratio: 1;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #32c98e;
      width: 2rem;
      font-size: 1.2rem;
      font-weight: bold;
    }

    img {
      display: block;
      width: 2rem;
    }
  }

  &__mobile {
    display: contents
  }

  &__main {
    width: var(--container-width);
    padding: 1rem 2rem;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
  }

  &__right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
  }

  &__middle {
    &__rencontre {
      .fa-map-marker:before {
        color: #fff;
        font-size: 20px;
      }
    }
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 0 1rem;
  }

  &__link {
    color: white;
    padding: 1rem;
    display: block;
    font-size: 1.6rem;
    letter-spacing: 1px;
    font-weight: 300;
    position: relative;

    &:not(.header__link--plq) {
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background: #fff;
        content: '';
        transform-origin: center;
        .scale(0);
        .transition(all ease 500ms);
      }

      i {
        font-style: normal;
        margin-right: .5rem;
      }

      &:hover {
        &:before {
          transform: scaleX(1);
        }
      }
    }
  }

  &__cta {
    font-size: 1.6rem;
    letter-spacing: 1px;
    background: linear-gradient(45deg, #b36b03, #d2a134, #f1d557, #d9ad3c, #b16700);
    color: black;
    font-weight: bold;
    transition: all ease 500ms;

    &:hover {
      transform: scale(1.025);
      box-shadow: 0 5px 15px rgba(179, 107, 3, 0.3), 0 15px 35px rgba(179, 107, 3, 0.3);
    }
  }

  &__logo {
    width: 16rem;
    height: 6rem;
    padding: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    display: block;

    img {
      display: block;
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__rencontre,
  &__toggle {
    display: none;
  }
}

.search-bar {
  display: flex;
  align-items: center;
  position: relative;

  &__input {
    border: none;
    display: block;
    height: 100%;
    text-indent: 2rem;
    background: #fff;
    outline: none;
    .transition(width ease 500ms);
    appearance: none;
    transition-delay: 10ms;
    width: 0;
    padding: 1rem 0;
    position: absolute;
    left: 4rem;
    z-index: 2;

    &:focus, &:hover, &:active, &:valid {
      width: 20rem;
    }
  }

  &:hover {
    .search__input {
      width: 20rem;
    }
  }
}

@media screen and (max-width: 920px) {
  .header {
    position: sticky;
    top: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(5px);

    &__main {
      padding: 1rem;
      justify-content: center;
    }

    &__mobile {
      position: fixed;
      z-index: 2;
      top: 0;
      left: 0;
      padding: 2rem;
      display: block;
      height: 0;
      width: 0;
      transition: all ease 500ms;
      z-index: 5;

      &:before {
        content: '';
        width: 100vw;
        height: 100vh;
        position: fixed;
        background: rgba(255, 255, 255, 0.9);
        left: 0;
        top: 0;

        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: all ease 500ms;
      }

      &:after {
        content: '';
        position: absolute;
        top: 42px;
        left: 40px;
        background: @light-black;
        border-radius: 50%;
        background-clip: padding-box;
        display: block;
        backface-visibility: hidden;
        height: 200vh;
        aspect-ratio: 1;
        transition: all ease 500ms;
        .transform(translate3d(-50%, -50%, 0) scale(.01));
        transition-delay: 50ms;
        z-index: 2;
      }
    }

    .header {
      &__right {
        display: none;
        flex-direction: column;
        padding: 0;
        max-height: calc(100vh - 5rem);
        max-width: 50vh;
        overflow: auto;
      }
    }

    &__left {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &__rencontre {
      display: block;
    }

    &__toggle {
      outline: none !important;
      background: #232323;
      border-radius: 50%;
      z-index: 50;
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 5px;
      padding: 18px 15px;

      &__bar {
        width: 25px;
        background: var(--main-color);
        height: 3px;
        display: block;
        border-radius: 1rem;

        &--short {
          width: 15px;
        }
      }
    }

    &__list {
      display: contents;
    }

    &__cta {
      background: transparent;
      width: auto;
      padding: 0.5rem 0;
      color: white;
      font-weight: normal;
      text-transform: none;

      i {
        align-items: center;
        justify-content: center;
        border: 1px solid #575757;
        height: 4rem;
        width: 4rem;
        border-radius: 4rem;
        margin-right: 2rem;
        display: inline-flex;
      }
    }

    &__item {
      padding: 0;
      z-index: 3;

      &--search {
        order: -1;
        padding-left: 6rem;
        margin-bottom: 1rem;
        padding-right: 1rem;
      }
    }

    &__link {
      &:not(.search-bar__toggle) {
        width: 100%;
        padding: .5rem 0;

        i {
          font-style: normal;
          align-items: center;
          justify-content: center;
          border: 1px solid #575757;
          height: 4rem;
          width: 4rem;
          border-radius: 4rem;
          margin-right: 2rem;
          display: inline-flex;
        }
      }

      &--plq {
        i {
          border: none !important;
        }
      }
    }

    &--open {
      .header {
        &__mobile {
          height: 100vh;
          width: 100vw;
          overflow: hidden;

          &:before {
            opacity: 1;
            visibility: visible;
          }

          &:after {
            transition: all ease 500ms;
            .transform(translate3d(-50%, -50%, 0) scale(1));
          }
        }

        &__right {
          display: flex;
        }
      }
    }
  }

  .search-bar {
    position: relative;
    width: 100%;

    &__input {
      width: 100% !important;
      padding: 2rem 3rem;
      font-size: 1.5rem;
      letter-spacing: 1px;
      font-weight: lighter;
      position: relative;
      left: 0;
      z-index: 1;
    }

    &__toggle {
      position: absolute;
      z-index: 2;
      color: #b5b5b5;
      right: 1rem;


      i:before {
        content: "\f061";
      }
    }

    &:after {
      content: '\f002';
      font-family: "font awesome 5 pro";
      position: absolute;
      background: 0 0;
      border: none;
      left: 2rem;
      color: #9e9e9e;
      top: 50%;
      .translate(0, -50%);
      z-index: 6;
    }
  }
}