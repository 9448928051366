.video-item {
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: calc((390 / 280) * 100%);
  border-radius: 0;
  margin-bottom: 1rem;
  overflow: hidden;
  transition: all ease 500ms;
  background: @light-black;

  &__thumb {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
  }

  &__infos {
    position: absolute;
    width: 100%;
    z-index: 1;
    background: @light-black;
    display: flex;
    flex-direction: column;
    top: 100%;
    padding: 2rem;
    transition: all ease-out 250ms;
  }

  &__title {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    color: #fff;
  }

  &__subtitle {
    color: #9e9e9e;
    font-size: 1.2rem;
    margin: 0;
  }

  &__play {
    cursor: pointer;
    background: #e9bf3a;
    border-radius: 50%;
    position: absolute;
    width: 4rem;
    height: 4rem;
    align-items: center;
    justify-content: center;
    display: flex;
    right: 1rem;
    top: 0;
    -webkit-transition: all ease 1000ms;
    -moz-transition: all ease 1000ms;
    -o-transition: all ease 1000ms;
    transition: all ease 1000ms;
    color: white;

    .fas {
      left: 1px;
      position: relative;
    }
  }

  &__see-more {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    z-index: 1;
    .translateY(-50%);
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 700;
  }

  &:hover {
    transform: scale(1.025);
    box-shadow: 0 5px 15px rgb(0 0 0 / 20%), 0 15px 35px rgb(0 0 0 / 15%);
    z-index: 2;

    .video-item {
      &__play {
        top: -2rem;
      }

      &__infos {
        transform: translateY(-100%);
      }
    }
  }

  &--last {
    .video-item__thumb {
      opacity: .15;
    }
  }
}
