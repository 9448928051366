.main {
  flex: 1;
  width: 100%;

  &__content-tgp {
    .main__content::before {
      display: none !important;
    }
  }
  &__content {
    position: relative;
  }

  &__section {
    margin: 2rem 0;

    &:first-child {
      margin-top: 0;
    }

    &--black {
      background: @light-black;
    }

    &--bottom-black {
      position: relative;

      > * {
        z-index: 2;
        position: relative;
      }

      &:after {
        background: @light-black;
        content: '';
        width: 100%;
        height: 75%;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
      }
    }
  }

  @media screen and (max-width: 920px) {
    &__section {
      &--bottom-black:after {
        height: calc(100% - 20rem);
      }
    }

    &--zm {
      .main {
        &__content {
          &:before {
            height: 5rem;
          }
        }
      }
    }
  }
}

.force-blur {
    filter: blur(10px) brightness(0.5)!important;
    filter: brightness(0.5);
}

.partially-disabled-message {
  position: absolute;
  color:white;
  text-align: center;
  z-index: 1;
  top: 40%;
  transform: translateY(-50%);
  width: 100%;
  padding: 1rem;
  font-size: clamp(1vw, 20px, 1.6rem);
}

@media screen and (max-width: 920px) {
  .partially-disabled-message {
    top: 15%;
  }
}
