.scene-item {
  position: relative;
  display: block;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  color: white;
  margin-bottom: 2rem;

  &__cover {
    padding-bottom: 62%;
    position: relative;
  }

  &__cta {
    position: absolute;
    top: 55%;
    left: 50%;
    .translate(-50%, -50%);
    display: block;
    background: var(--main-color);
    font-size: clamp(1.2rem, 1.5vw, 1.5rem);
    white-space: nowrap;
    color: white;
    text-transform: uppercase;
    padding: 1rem 2rem;
    border-radius: 2rem;
    letter-spacing: 1px;
    z-index: 1;
    opacity: 0;
    transition: all ease 500ms;
    text-align: center;
    line-height: 1;
  }

  &__quality {
    padding: 1rem;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    right: 1rem;
    top: 1rem;
    border-radius: 1rem;
    color: white;
    line-height: 1;
    font-weight: 900;
    z-index: 1;
  }

  &__infos {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.5rem;

    &__left {
      flex: 1;
      overflow: hidden;
      text-align: left;
    }

    &__right {
      text-align: right;
    }
  }

  &__cover__img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    font-size: 2rem;
    color: white;
    font-weight: 300;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__subtitle {
    font-weight: 300;
    color: white;
    opacity: .6;
  }

  &__notation {
    font-size: 2rem;
    font-weight: 300;
    color: var(--main-color);
    display: flex;
    justify-content: flex-end;

    &:before {
      content: '\f005';
      font-family: 'Font Awesome 5 Pro', sans-serif;
      font-weight: 300;
      font-size: 17px;
      display: block;
      margin-right: .5rem;
    }
  }

  &:hover .scene-item__cta {
    opacity: 1;
    top: 50%;
  }

  @media screen and (max-width: 920px) {
    margin-bottom: 1rem;

    &__infos {
      flex-direction: column;

      &__right {
        text-align: left;
      }
    }

    &__title {
      font-size: 1.5rem;
    }

    &__subtitle {
      font-size: 1.2rem;
    }

    &__notation {
      justify-content: flex-start;
      align-items: center;
      font-size: 1.5rem;

      &:before {
        font-size: 1.5rem;
      }
    }
  }
}
