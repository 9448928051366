.partner-details {
  border-radius: 2rem;
  overflow: hidden;
  background: black;

  &__handler {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    height: 0;
    padding-bottom: 35%;
    z-index: 25;
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: grid;
    grid-template-columns: 20% 1fr;
    align-items: flex-end;
    grid-gap: 2rem;
    padding: 0 2rem;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 80%;
      bottom: 0;
      background: -moz-linear-gradient(bottom, #000000 30%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 65%);
      background: -webkit-linear-gradient(bottom, #000000 30%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 65%);
      background: linear-gradient(to top, #000000 30%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0) 65%);
      z-index: -1;
      overflow: hidden;
    }
  }

  &__poster {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
    border-radius: 1rem;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }

  &__texts {
    color: white;
    display: grid;
    grid-row-gap: 1rem;
  }

  &__title {
    line-height: 1.2;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    text-shadow: #000 0 0 15px;
  }

  &__infos {
    display: flex;
    flex-wrap: wrap;
  }

  &__info {
    padding-right: 1rem;
    font-size: 1.8rem;
    color: var(--main-color);

    a {
      color: var(--main-color);
    }

    strong {
      color: white;
    }
  }

  &__description {
    color: white;
    padding: 2rem;
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 0;
  }

  &__offer {
    padding: 0 2rem 2rem 2rem;
  }

  @media screen and (max-width: 920px) {
    &__content {
      grid-gap: 1rem;
      grid-template-columns: 25% 1fr;
    }

    &__texts {
      grid-gap: .5rem;
    }
  }
}
