.footer {
  padding: 5rem 1rem;
  display: grid;
  grid-row-gap: 2rem;
  margin: 0 auto;
  width: 100%;
  max-width: var(--container-width);
  color: white;

  a {
    color: white;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    color: #8f8f8f;
  }

  &__logo {
    background-size: contain;
    width: 15rem;
    height: 5rem;
    background-repeat: no-repeat;
    background-position: center;
    display: block;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__right {
    text-align: right;
    font-weight: bold;
  }

  &__links {
    margin: 0 1rem;
    display: flex;
    flex-wrap: wrap;
    gap:.5rem;
  }

  &__link {
    font-size: 1.2rem;
    display: block;
    padding: 1rem 2rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 1rem;
    font-weight: bold;
    color: white;
  }

  &__social-item {
    display: block;
    font-size: 2.5rem;
    margin: 0.25rem;

    .visa-mastercard {
      width: 15rem;
      margin-left: 1rem;
    }

    img {
      width: 5rem;
      display: block;
    }

    svg,
    i {
      padding: 0 0.5rem;
      width: 2.5rem;
      height: 2.5rem;
      background: white;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 1.4rem;
    }
  }

  &__social-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__row {
    &--websites {
      border-top: 1px solid #2c2c2c;
      padding-top: 2rem;
    }

    &--centered {
      text-align: center;
      font-size: 15px;
    }

    &--spaced {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__dropdown {
    &__button {
      background: transparent;
      font-size: 1.4rem;

      &:before {
        font-family: "Font Awesome 5 Pro";
        content: @fa-var-globe;
        margin-right: .5rem;
        font-weight: 500;
      }

      &:hover {
        color: var(--main-color);
      }
    }

    &__content {
      background: #343434;
      padding: 0;
      border-radius: .5rem;
      width: 100%;
      border: none;

      >a {
        padding: 1rem 1.5rem;
        font-size: 1.4rem;
        border-bottom: 1px solid #535353;
        display: block;
        color: @dark-gray;
        text-decoration: none;

        &:last-of-type {
          border: none;
        }

        &:active,
        &:focus,
        &:hover {
          background: rgba(158, 158, 158, 0.2);
        }
      }
    }
  }

  &__teenager-protection {
    a {
      color: var(--main-color);
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1rem;
  }

  &__grid {
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
      text-align: center;
      display: flex;
      align-items: center;

      a {
        display: block;
        width: 100%;
        padding: 1rem;
        font-weight: 400;
        font-size: 1.4rem;
        color: #8f8f8f;

        &:hover {
          color: var(--main-color);
          font-weight: bold;
        }
      }

      &:not(:last-child):after {
        content: '';
        display: block;
        width: .25rem;
        height: .25rem;
        background: #5b5b5b;
        border-radius: 1rem;
      }
    }
  }

  &__adress {
    font-size: 1.2rem;
    margin-top: .5rem;
    opacity: .5;
  }

  @media screen and (max-width: 980px) {
    padding: 5rem 1rem 10rem 1rem;

    &__row {
      &--spaced {
        flex-direction: column;
      }
    }

    &__grid {
      flex-wrap: wrap;

      &__item {
        flex: auto;
        width: 50%;

        &:nth-child(2n) {
          &:after {
            content: none;
          }
        }
      }
    }

    &__left {
      flex-direction: column;
      width: 100%;
    }

    &__right {
      text-align: center;
      margin-top: 1rem;
    }

    &__social-list {
      align-items: center;
      justify-content: center;
    }

    &__links {
      align-items: center;
      justify-content: center;
      margin: 0;
      margin-top: 1rem;
    }

    &__link {
      padding: 1rem;
      font-size: 1.2rem;
    }
  }
}
