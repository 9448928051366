.premium-offers {
  position: relative;
  margin-top: -2rem;
  padding-bottom: 2.5rem;
  color:white;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 70%;
    background: #232323;
  }

  &__header {
    text-align: center;
    margin-bottom: 5rem;
    position: relative;
    padding-top: 5rem;
  }

  &__title {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    font-size: 2.5rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  &__subtitle {
    letter-spacing: 1px;
    font-size: 1.8rem;
    font-weight: 100;
    margin: 0;
    line-height: 1;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
}

.premium-offer {
  padding: 5rem 2rem;
  width: 33%;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  position: relative;
  background-size: cover;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;

  &__title {
    color: black;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: clamp(2rem, 2vw, 2.5rem);
    line-height: 3.5rem;
    margin-bottom: 3rem;

    strong {
      display: block;
      font-size: clamp(2.5rem, 4vw, 4rem);
    }
  }

  &__price {
    color: @black;
    font-weight: 700;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-end;

    &__left {
      font-size: 10rem;
      line-height: 8rem;
    }

    &__right {
      font-size: 5rem;
      text-align: left;
    }

    &_currency {
      display: block;
      line-height: 4rem;
    }

    &__decimal {
      display: block;
      font-size: 2rem;
      letter-spacing: 1px;
      font-weight: normal;
      margin-bottom: -5px;
    }

    &__subtitle {
      display: block;
      text-transform: uppercase;
      font-weight: normal;
      width: 100%;

      &--top {
        margin-bottom: .5rem;
      }

      &--bottom {
        margin-top: 1rem;
      }
    }
  }

  &__description {
    color: black;
    margin-top: 3rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 2rem;
  }

  &__cta {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 70%;
    color: #fff;
    border: none;
    padding: 1.5rem;
    background: @black;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 2rem;
    cursor: pointer;

    &:hover {
      background: @pastel-green;
    }
  }

  &--main {
    background-image: url("../img/premium_pack.png");
    border: 3px solid @pastel-green;

    .premium-offer {
      &__price {
        color: @pastel-green;

        &__subtitle {
          color: @pastel-green;
        }

        &__left {
          width: 7rem;
        }
      }

      &__cta {
        background: @pastel-green;
      }
    }
  }

  &--left,
  &--right {
    background-image: url("../img/streaming_pack.png");
    .scale(.8);
  }

  &--left {
    .transform-origin(100%);
  }

  &--right {
    .transform-origin(0);
  }
}

@media screen and (max-width: 920px) {
  .premium-offers {
    &__list {
      flex-direction: column;
    }

    &:before {
      height: 100%;
    }
  }

  .premium-offer {
    width: 100%;
    transform: scale(1);
    margin-bottom: 2rem;
    border-radius: 2rem;
    padding: 2rem;

    &__title {
      font-size: 1.5rem;
      line-height: 1;
      margin-bottom: 1rem;

      strong {
        font-size: 3rem;
      }
    }

    &__description {
      margin: 1rem;
    }

    &__price {
      &__right {
        line-height: 1;
      }


      &__subtitle--top {
        margin: 0;
      }
    }

    &__cta {
      position: relative;
      left: 0;
      transform: none;
      width: 100%;
      font-size: 1.3rem;
      padding: 1rem;
    }
  }
}
