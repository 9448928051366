.actor-list {
  &--top {
    z-index: 2;
    position: relative;
  }

  &--carousel {
    z-index: 2;
    position: relative;
    padding-top: 1rem;
    display: none;

    &.swiper-initialized {
        display: block;
    }
  }

  @media screen and (min-width: 768px) {
    &--carousel {
      .gl__col {
        &:nth-child(-n+3) {
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 920px) {
    &--top {
      padding-bottom: 2rem;
    }
  }

  @media screen and (max-width: 768px) {
    &--carousel {
      .gl__col {
        display: none;

        &:nth-child(-n+2) {
          display: block;
        }
      }
    }
  }
}
