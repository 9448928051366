.public-form {
  z-index: 2;
  position: relative;
  max-width: 90%;
  width: 70rem;
  margin: 0 auto;
  border-radius: 1rem;
  padding: 5rem;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
  background-color: #fff;

  &--login {
    background-image: url("../img/background-login.jpg");
  }

  &--contact {
    background-image: url("../img/background-contact.jpg");
  }

  &--error {
    background-image: url("../img/background-error.jpg");
    width: 50rem;
  }

  &--register {
    background-image: url("../img/background-sign.jpg");
  }

  &--split {
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: bottom right;

    .public-form__content {
      z-index: 2;
      position: relative;
      width: 50%;
    }
  }

  &__content {
    display: grid;
    grid-gap: 2rem;
  }

  &__text {
    a {
      color: var(--main-color);
    }

    .identity img {
      height: 10px;
    }
  }

  &__title {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 2.8rem;
    position: relative;
    padding-bottom: 1rem;
    text-align: center;

    &:after {
      content: '';
      position: absolute;
      height: 3px;
      width: 6rem;
      background-color: #e9bf3a;
      left: 50%;
      bottom: 0;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }

  &__subtitle {
    font-size: 1.3rem;
    text-align: center;

    &--blue {
      background: fadeOut(@blue, 80%);
      padding: 1rem;
      border: 1px solid @blue;
      color: @blue;
      border-radius: 1rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    align-items: center;

    &--center {
      justify-content: center;
    }
  }

  &__link {
    color: @light-black;
    margin-top: 1rem;
    font-size: 1.2rem;
    outline: none;
    cursor: pointer;
    text-align: center;

    &--bordered {
      border: 1px solid @light-black;
      padding: 1rem 2rem;
    }
  }

  @media screen and (max-width: 920px) {
    padding: 3rem;
    max-width: 100%;

    &__title{
      font-size: 2rem;
    }

    &--split {
      background: none;
      background-color: white;

      .public-form__content {
        width: 100%;
      }
    }
  }
}

.private-form {
  z-index: 2;
  position: relative;
  max-width: 90%;
  width: 60rem;
  margin: 0 auto;
  border-radius: 1rem;
  padding: 3rem;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
  background-color: #fff;

  &__title {
    margin: 0;
    font-size: 2rem;
    color: @black;
    position: relative;
    padding: 0 2rem;
    font-weight: 700;
    margin-bottom: 2rem;

    &--no-margin {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      height: 100%;
      width: .2rem;
      background-color: @yellow;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 920px){
    margin: 0;
    max-width: 100%;
  }
}

.form {
  display: grid;
  grid-row-gap: 2.5rem;

  &__label {
    color: @dark-gray;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }

  &__input {
    outline: none;
    width: 100%;
    padding: 1rem 0;
    border: none;
    background: 0 0 !important;
    border-bottom: 2px solid @gray;
    color: @light-black;
  }

  &__checkbox {
    display: flex;
    align-items: baseline;

    label {
      color: #9e9e9e;
      text-align: left;
      display: block;
      position: relative;
      cursor: pointer;

      &:before {
        content: '\f111';
        left: 0;
        font-family: 'Font Awesome 5 Pro';
        color: #9e9e9e;
        font-size: 1.5rem;
        margin-right: 1rem;
        top: 50%;
        .translate(0%, -50%)
      }
    }

    input {
      display: none;

      &:checked + label:before {
        content: '\f058';
        color: var(--main-color);
        font-weight: 600
      }
    }
  }

  &__select {
    position: relative;

    &:after {
      content: '';
      width: 0;
      border-style: solid;
      border-width: 6px 4px 0;
      border-color: #111 transparent transparent transparent;
      position: absolute;
      pointer-events: none;
      top: 50% !important;
      right: 1rem;
      transform: translateY(-50%);
    }

    select {
      color: @dark-gray;
      width: 100% !important;
      font-size: inherit;
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      -moz-box-sizing: border-box;
      height: 4rem;
      border-radius: 0;
      background: #fff;
      border: 0;
      border-bottom: 2px solid @gray;
    }
  }

  &__error {
    color: @red;
    font-size: 1.2rem;

    &--center {
      text-align: center;
    }
  }

  &__group {
    &--has-error {
      label {
        color: @red;
      }

      input {
        border-bottom: 2px solid @red;
      }
    }
  }

  &__submit {
    width: 90%;
    font-size: 1.8rem;
    color: #fff;
    letter-spacing: 1px;
    background: var(--main-color);
    border: none;
    padding: 1rem;
    box-shadow: 0 15px 35px rgb(233 191 58 / 10%), 0 5px 15px rgb(233 191 58 / 50%);
    -webkit-transition: all ease 250ms;
    -moz-transition: all ease 250ms;
    -o-transition: all ease 250ms;
    transition: all ease 250ms;
    position: relative;
    outline: none;

    &--center {
      margin: 0 auto;
    }
  }
}
