.actor-item {
  display: block;
  position: relative;
  margin-bottom: 2rem;

  &__thumb {
    position: relative;
    overflow: hidden;
    padding-top: calc(130 / 93 * 100%);
    width: 100%;
    height: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    .transition(all ease 250ms);

    &__img {
      width: calc(100% + 1px);
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      background: white;
      display: block;
    }
  }

  &__vote {
    outline: none;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.8);
    transition: all ease 250ms;
    border: none;
    color: #fff;
    border-radius: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 4rem;
    width: 4rem;
    right: 0;
    bottom: 0;
    z-index: 2;
    transform: translate(-50%, 100%);

    &--like {
      background: @green;

      i {
        animation: tada 2s linear;
      }
    }

    &--liked {
      background: @red;
    }
  }

  &__title {
    color: white;
    font-size: 2rem;
    margin-top: 1rem;
    letter-spacing: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    text-align: center;
  }

  &__subtitle {
    color: var(--main-color);
    font-size: 1.8rem;
    text-align: center;
    letter-spacing: 2px;
    font-weight: 400;
    margin: 0;
  }

  &:hover {
    .actor-item {
      &__thumb {
        transform: scale(1.02);
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
      }

      &__vote {
        transform: translate(-50%, -50%);
      }
    }
  }

  &--ranking {
    margin: 5rem 0;
    position: relative;

    .actor-item__subtitle {
      display: none;
    }

    &:before {
      content: attr(data-rank);
      font-weight: 700;
      font-size: 10rem;
      color: #9e9e9e;
      position: absolute;
      top: 0;
      line-height: 8rem;
      left: 2rem;
      .translate(0, -75%);
      opacity: .4;
    }
  }

  &--black-background {
    .actor-item {
      &__title {
        color: white;
      }
    }
  }

  @media screen and (max-width: 920px) {
    &__title {
      font-size: 1.5rem;
    }

    &__vote {
      transform: translate(0%, 0%);
      bottom: 1rem;
      right: 1rem;
    }

    &--ranking {
      margin-bottom: 2rem;
      margin-top: 3rem;

      &:before {
        font-size: 6rem;
        transform: translate(0, -65%);
        line-height: 1;
      }
    }
  }
}
