.video-banner {
  position: relative;
  display: block;

  &__cover {
    display: block;
    position: relative;
    padding-bottom: calc(100% * (650 / 1920));
    width: 100%;
    height: 0;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    overflow: hidden;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 920px) {

    &__cover {
      padding-bottom: calc(100% * (650 / 650));
    }
  }
}

.elite-film {
  display: block;
  position: relative;
  padding-top: calc(63 / 185 * 100%);
  width: 100%;
  height: 0;
}
