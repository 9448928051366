.video-detail {
  position: relative;
  z-index: 1;

  &__toggle-player {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }

  &__content {
    z-index: 2;
    //width: 100%;
    //display: grid;
    //grid-template-columns: 25% 1fr;
    //grid-gap: 2rem;
    padding: 3rem 0 3rem 3rem;
    pointer-events: none;
    position: relative;
    align-items: flex-start;

    > * {
      pointer-events: all;
    }
  }

  &__poster {
    position: relative;
    overflow: hidden;
    margin-top: -50%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), 0 15px 35px rgba(0, 0, 0, 0.1);

    &:hover {
      .video-detail__poster__cta {
        .translateY(-50%);
        opacity: 1;
      }
    }

    &__cta {
      position: absolute;
      width: calc(100% - 4rem);
      left: 2rem;
      top: 50%;
      opacity: 0;
      .translateY(0%);
      transition: all ease 250ms;
      cursor: pointer;
      text-transform: uppercase;
      padding: 1.25rem 4rem;
      letter-spacing: 1px;
      font-weight: 600;
      border: none;
      line-height: 1;
      font-size: 1.5rem;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    &__img {
      display: block;
      width: 100%;
    }
  }

  &__texts {
    color: white;
    display: grid;
    grid-row-gap: 1rem;
  }

  &__title {
    line-height: 1.2;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    text-shadow: #000 0 0 15px;
  }

  &__infos {
    display: flex;
    flex-wrap: wrap;
  }

  &__info {
    padding-right: 1rem;
    font-size: 1.8rem;
    color: var(--main-color);

    a {
      color: var(--main-color);
    }

    strong {
      color: white;
    }
  }

  &__description {
    font-size: 1.6rem;
    text-align: left;
    margin-bottom: 0;
    max-height: 11rem;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 15px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: transparent /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ffffffe8; /* color of the scroll thumb */
      border-radius: 10px; /* roundness of the scroll thumb */
      border: 5px solid black; /* creates padding around scroll thumb */
    }
  }

  &__ctas {
    display: flex;
    align-items: center;

    &--fixed {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 3;
      box-shadow: 0 5px 15px rgb(0 0 0 / 20%), 0 15px 30px rgb(0 0 0 / 15%);
      width: 100%;
      background: @light-black;
      padding: 1.5rem;
      display: flex;
      justify-content: center;

      .cta {
        margin: 0 1rem;
        padding: 1rem 3rem;
      }
    }
  }

  &__cta {
    cursor: pointer;
    text-transform: uppercase;
    padding: 1.25rem 4rem;
    letter-spacing: 1px;
    font-weight: 600;
    border: none;
    line-height: 1;
    font-size: 1.5rem;
    margin-right: 1rem;

    i {
      margin-left: 1rem;
      margin-right: 0;
    }
  }

  &__notation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    [data-stars-container] {
      display: contents;
    }

    &__stars {
      position: relative;

      &:not(:checked) {
        > input {
          position: absolute;
          top: -9999px;
          clip: rect(0, 0, 0, 0);
        }

        > label {
          width: 2.5rem;
          padding: 0 .05em;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
          font-size: 2rem;
          color: rgba(255, 255, 255, 0.3);
          margin-bottom: 0;
          float: right;

          &:before {
            content: @fa-var-star;
            font-weight: 900;
            font-family: 'Font Awesome 5 Pro';
          }

          &:hover, &:hover ~ label {
            color: @yellow;
            text-shadow: 0 0 3px @yellow;
          }
        }
      }

      > input:checked ~ label {
        color: @yellow;
      }

      > label {
        position: relative;
      }
    }

    &__label {
      color: white;
      vertical-align: top;
      font-weight: bold;
      font-size: 1.8rem;
      margin-bottom: 0;
      margin-right: .5rem;
    }

    &__message {
      width: 100%;
      font-weight: bold;

      &--valid {
        font-weight: bold;
        color: @green;
      }

      &--error {
        color: @red;
      }
    }
  }

  &__gallery-item {
    display: block;
    overflow: hidden;
    margin-bottom: 1rem;

    &__img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 4/3;
      display: block;
    }
  }
}

.video-player {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;

  > * {
    pointer-events: all;
  }

  > img:not(.video-player__4k) {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }

  .video-js {
    display: none;
  }

  &__4k {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 5rem;
    z-index: 1;

  }

  &__play {
    z-index: 21;
    position: absolute;
    left: 50%;
    top: 50%;
    border: 3px solid #fff;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px 0 #000, inset 0 0 5px 0 #000;
    outline: none;
    cursor: pointer;

    backdrop-filter: blur(5px);
    background: rgb(0 0 0 / 50%);

    &:before {
      content: @fa-var-play;
      font-family: 'font awesome 5 pro';
      color: #fff;
      font-size: 3.7rem;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-40%, -50%);
      text-shadow: 0 0 10px #000;
      font-weight: 900;
    }
  }

  &__content {
    position: relative;
    overflow: hidden;
  }

  &__poster {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 3;
    width: 25%;

    &--video-paused {
      bottom: 4rem;
    }

    &:hover {
      .video-player__poster__cta {
        .translateY(-50%);
        opacity: 1;
      }
    }

    &__cta {
      position: absolute;
      width: calc(100% - 4rem);
      left: 2rem;
      top: 50%;
      opacity: 0;
      .translateY(0%);
      transition: all ease 250ms;
      cursor: pointer;
      text-transform: uppercase;
      padding: 1.25rem 4rem;
      letter-spacing: 1px;
      font-weight: 600;
      border: none;
      line-height: 1;
      font-size: 1.5rem;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    }

    &__img {
      display: block;
      width: 100%;
    }
  }

  &__ctas {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  &__cta {
    text-transform: uppercase;
    padding: 1.5rem 4rem;
    letter-spacing: 1px;
    font-weight: 600;
    display: inline-block;
    color: #fff;
    margin: 0 .5rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.confirmation_buy {
  text-align: center;
  padding: 1.5rem;
  background: fadeout(@green, 80%);
  border-radius: 1.5rem;
  margin-bottom: 1rem;
  color: @green;
  border: 1px solid @green;
  font-weight: bold;
}


/* ********* */
@media screen and (max-width: 1280px) {
  .video-detail {
    &__play {
      display: none;
    }
  }
}

@media screen and (max-width: 920px) {
  .video-detail {
    &__content {
      position: relative;
      left: 0;
      top: 0;
      height: auto;
      grid-gap: 0;
      display: flex;
      padding: 0;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }

    &__texts {
      width: 100%;
    }

    &__play {
      top: calc(56.5vw / 2);
      width: 7rem;
      height: 7rem;
      display: block;

      &:before {
        font-size: 2rem;
      }
    }

    &__poster {
      width: calc(100% / 3);
      position: relative;
      aspect-ratio: 3/4;
      background: white;
      margin-bottom: 1rem;
      margin-top: 0;

      &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0;
        display: block;
      }
    }

    &__notation__label,
    &__info {
      font-size: 1.5rem;
    }

    &__ctas {
      flex-wrap: wrap;
    }

    &__cta {
      margin: .5rem;
    }

    &__description {
      font-size: 1.5rem;
    }
  }

  .video-player {
    //position: fixed;
    //top: 0;
    width: 100%;
    //left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    //padding: 1rem;
    justify-content: center;

    &__play {
      z-index: 21;
      width: 7rem;
      height: 7rem;

      &:before {
        font-size: 2.4rem;
      }
    }

    &__poster {
      position: absolute;
      bottom: .4rem;
      left: .4rem;
      z-index: 20;
      width: 25%;

      &--video-paused {
        bottom: 4rem;
      }

      &:hover {
        .video-player__poster__cta {
          .translateY(-50%);
          opacity: 1;
        }
      }

      &__cta {
        position: absolute;
        width: calc(100% - 4rem);
        left: 2rem;
        top: 50%;
        opacity: 0;
        .translateY(0%);
        transition: all ease 250ms;
        cursor: pointer;
        text-transform: uppercase;
        padding: 1.25rem 4rem;
        letter-spacing: 1px;
        font-weight: 600;
        border: none;
        line-height: 1;
        font-size: 1.5rem;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
      }

      &__img {
        display: block;
        width: 100%;
      }
    }
  }
}
