.userstate {
  [data-is-logged] {
    display: none;
  }

  &--is-authenticated {
    [data-is-logged] {
      display: initial;
    }

    [data-is-anonymous] {
      display: none;
    }
  }
}

.user-is-authenticated {
  .userstate {
    &__hidden-to-show {
      display: initial;
    }

    &__hidden-to-show {
      &__force {
        display: initial !important;
      }
    }

    &__to-hide {
      display: none;
    }
  }
}

.user-has-subscription {
  .has_sub_hide {
    display: none;
  }
}
