.partners-item {
  display: block;
  margin-bottom: 1rem;

  &__thumb {
    background-image: url('../img/no-camera.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-bottom: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);

    &__img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &__title {
    text-align: center;
    color: @dark-gray;
    margin-bottom: 0;
  }
}
