.actor-details {
  display: grid;
  grid-template-columns: 25% 1fr;

  &__left {
    position: relative;
  }

  &__img {
    position: relative;
    overflow: hidden;
    z-index: 2;
    padding-top: calc(130 / 93 * 100%);
    width: 100%;
    height: 0;
    box-shadow: 0 5px 15px rgb(0 0 0 / 15%);
    background: white;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
    }
  }

  &__title {
    margin-top: 2rem;
    margin-left: 2rem;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 3rem;
  }

  &__subtitles {
    color: white;
    display: flex;
    margin-left: 2rem;
  }

  &__subtitle {
    display: inline-block;
    color: #e9bf3a;
    text-transform: uppercase;
    margin-right: 1rem;
    margin-bottom: 0;
    font-size: 1.5rem;

    strong {
      color: white;
    }
  }

  &__description {
    padding: 2rem;
    background: #efeeee;
    margin-top: 2rem;
    margin-left: -10rem;
    padding-left: 13rem;

    &__title {
      margin: 0;
      font-size: 3rem;
      letter-spacing: 1px;
      color: #111111;
      position: relative;
      padding: 0 2rem;
      font-weight: bold;
      margin-bottom: 2rem;

      &:before {
        content: '';
        height: 100%;
        width: 0.2rem;
        background-color: var(--main-color);
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #d1d1d1;
  }

  &__link {
    color: #232323;
    display: grid;
    align-items: center;
    grid-template-columns: 2.5rem 1fr;
    grid-gap: .5rem;
    white-space: nowrap;
    font-weight: 700;
    margin-right: 1.5rem;

    &__img {
      width: 100%;
      border-radius: 50%;
    }
  }

  &__like {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem;
    z-index: 2;

    &__link {
      background: #16da5f;
      width: 100%;
      height: 100%;
      color: white;
      padding: 1rem;


      &:after {
        content: attr(data-text);
        font-weight: bold;
        margin-left: 1rem;
      }

      &--ok {
        &:after {
          content: attr(data-text-ok);
        }
      }

      &--error {
        background: @red;

        &:after {
          content: attr(data-text-error);
        }
      }
    }
  }

  @media screen and (max-width: 920px) {
    display: flex;
    flex-direction: column;
    background: #232323;
    margin: 0 -1rem;
    padding: 1rem;

    &__img {
      order: 2;
      margin-top: 1rem;
    }

    &__subtitles,
    &__title {
      order: 1;
      margin: 0;
      text-align: center;
      justify-content: center;
    }

    &__infos {
      display: contents;
    }

    &__description {
      order: 3;
      margin: 0;
      padding: 3rem;
      font-size: 1.5rem;
      margin-top: 2rem;
      border-radius: 1rem;
    }

    &__link {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
}
