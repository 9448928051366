.hero-header {
  position: relative;
  margin-bottom: 8rem;
  padding-bottom: 20%;
  height: 0;
  background-image: url('../img/cover-list.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    color: var(--main-color);
    text-transform: uppercase;
    font-weight: bold;
    font-size: 4rem;
    margin: 0;
    letter-spacing: 1px;
  }

  &__subtitle {
    color: white;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 2.5rem;
    margin: 0;
    letter-spacing: 1px;

    a {
      color: white;
      text-decoration: underline;
      font-size: 1.5rem;
    }
  }
}

.hero-header__filters {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
}


.filters {
  background: @light-gray;
  padding: 2rem;
  border-radius: 1rem;
  width: 95rem;
  max-width: 90%;
  display: block;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  z-index: 1;

  &__selected {
    display: flex;
    align-items: center;
    padding: 0 2rem;

    &-tags {
      display: flex;
      flex-wrap: wrap;
    }

    &-tag {
      margin: 0 .5rem;
      padding: .5rem 1rem;
      background-color: white;
      border-radius: 5px;
      display: block;
      color: @light-black;
      transition: all ease 250ms;

      &:hover {
        box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
      }

      i {
        margin-left: .5rem;
      }
    }
  }

  &--links {
    padding: 0;
    overflow: hidden;
  }

  &__list {
    display: flex;
    align-items: center;

    input::placeholder {
      color: black;
    }

    input{
      cursor: pointer;
    }

    > * {
      width: 100%;
      padding: 0 1rem;
    }
  }

  &__link {
    padding: 2rem;
    text-align: center;
    color: black;
    position: relative;

    &:hover {
      background: #e1e1e1;
    }

    &--active:after {
      position: absolute;
      width: 100%;
      content: '';
      height: 5px;
      background: var(--main-color);
      left: 0;
      bottom: 0;
    }
  }
}

.dropdown-filters {
  &__input {
    border: none;
    width: 100%;
    padding: 0;
    background: transparent;
    font-size: 1.2rem;
    letter-spacing: 1px;

    &::placeholder {
      font-weight: 700;
      color: black;
      text-transform: uppercase;
    }
  }

  &__toggle {
    border-radius: 0;
    width: 100%;
    border: none;
    padding: 1rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    letter-spacing: 1px;
    font-weight: 700;
    outline: none;
    border-bottom: 1px solid @gray;

    &:after {
      width: 0;
      height: 0;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      display: block;
      border-top-color: @gray;
    }
  }
}


.header_list_wrapper {

  .header_list_filters {
    position: relative;
    margin: -3rem auto 0 auto;
    background: @light-gray;
    max-width: 90%;
    width: 70rem;
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);

    .btn_group_list {
      display: flex;
    }

    .btn-group {
      width: 100%;
      display: block;

      .filter_btn {
        padding: 1.5rem 4rem;
        color: @light-black;
        font-size: 1.8rem;
        display: block;
        text-align: center;
        position: relative;

        &:after {
          height: 0;
          width: 100%;
          content: '';
          background: @yellow;
          position: absolute;
          bottom: 0;
          left: 0;
          .transition(all ease 250ms);
        }

        &:hover, &:active, &:focus {
          background-color: rgba(196, 196, 196, 0.2);
        }

        &.active, &:hover, &:active, &:focus {
          &:after {
            height: 3px;
          }
        }
      }

      &.active {
        .filter_btn {
          &:after {
            height: 3px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 920px) {
  .filters {
    width: 100%;
    max-width: 100%;
    position: initial;
    transform: translate(0, 0);
    left: 0;
    top: 0;
    padding: 1.5rem;

    &__list {
      flex-direction: column;

      & > * {
        padding: 0;
      }
    }

    &__selected {
      font-size: 1.2rem;
      padding: 0;
      padding-bottom: .5rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #c8c8c8;

      &-tag {
        margin: .25rem;
      }
    }

    &__link {
      padding: 1rem;
      border: 1px solid #e7e6e6;
      margin-bottom: 1rem;
      border-radius: 1rem;
      overflow: hidden;


      &--active:after {
        height: 2px;
      }
    }

    &--links {
      display: none;
    }
  }

  .dropdown-filters {
    &__toggle {
      padding: 1.5rem;
    }
  }

  .hero-header {
    height: auto;
    padding: 0 1rem;
    margin-bottom: 2rem;
    border-radius: 2rem;

    &__header {
      position: relative;
      padding: 3rem 1rem;
    }

    &__title {
      font-size: 2rem;
      line-height: 1;
      text-align: center;
    }

    &__subtitle {
      font-size: 1.5rem;
    }
  }
}


@media screen and (min-width: 920px) {
  .filters {
    &__backdrop,
    &__toggles {
      display: none;
    }
  }
}

@media screen and (max-width: 920px) {
  .filters {
    transform: none;
    padding: 1rem;

    &__modal {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      opacity: 0;
      display: flex;
      visibility: hidden;
      transition: opacity ease 500ms;

      &--open {
        opacity: 1;
        visibility: visible;
      }
    }

    &__backdrop {
      position: absolute;
      width: 100%;
      height: 100%;
      background: black;
      opacity: .8;
      z-index: 1;
    }

    &__list {
      background: #efeeee;
      width: 100%;
      border-radius: 1rem 1rem 0 0;
      max-height: 80vh;
      z-index: 2;
      overflow: auto;
      position: relative;
    }

    &__toggles {
      display: flex;
      justify-content: space-around;
      gap: 2rem;

      > * {
        flex: 1;
      }

      .dropdown-filters__toggle {
        border-bottom: none;
        padding: .5rem;
      }
    }

    &__toggle {
      padding: 0;
    }
  }

  .dropdown-filters {
    .dropdown__content {
      position: relative;
      margin: 0;
      box-shadow: none;
    }

    .dropdown-filters__content {
      margin: 0;
      box-shadow: none;
      border-bottom: 1px solid lightgrey;
      border-radius: 0;
    }
  }
}
