.home-slider {
  //padding-bottom: 3rem;

  --swiper-pagination-bullet-inactive-color: white;
  --swiper-theme-color: var(--main-color);
  .swiper-slide {
    opacity: .2;
    transition: opacity ease 500ms, filter ease 250ms;
    filter:blur(5px);

    &-active {
      opacity: 1;
      filter:blur(0);
    }
  }

  &__item {
    position: relative;
    display: block;
    width: 100%;
    aspect-ratio: 1920 / 650;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2), 0 15px 35px rgba(0, 0, 0, 0.15);

    @supports not (aspect-ratio: 1920 / 650) {
      padding-bottom: calc(100% * (650 / 1920));
    }

    * {
      pointer-events: none;
    }
  }

  &__cover {
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
  }

  @media screen and (max-width: 920px) {
    &__item {
      aspect-ratio: 1;
      @supports not (aspect-ratio: 1) {
        padding-bottom: calc(100% * (1080 / 1080));
      }
    }
  }
}
