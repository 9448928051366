.highlight-video {
  z-index: 1;
  position: relative;

  &__grid {
    display: grid;
    grid-template-columns: 50% 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    align-items: center;
  }

  &__left {
    display: grid;
    grid-row-gap: 1rem;
    padding-left: 3rem;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__subtitle {
    font-size: 1.8rem;
    letter-spacing: 2px;
    color: #fff;
    margin-top: 0;
    font-weight: 100;
    padding-right: .5rem;
  }

  &__title {
    text-transform: uppercase;
    margin: 0;
    font-size: 3rem;
    letter-spacing: 1px;
    color: #fff;
    font-weight: 700;
    position: relative;

    &:before {
      content: '';
      height: 100%;
      width: 0.2rem;
      background-color: #e9bf3a;
      position: absolute;
      top: 0;
      left: -3rem;
    }
  }

  &__list {
    display: flex;
    align-items: baseline;
    color: white;
    flex-wrap: wrap;
  }

  &__link {
    font-size: 1.8rem;
    color: var(--main-color);
    letter-spacing: 2px;
    font-weight: 300;
    padding: 0 .5rem;
  }

  &__description {
    font-size: 1.8rem;
    color: white;
    font-weight: 300;
  }

  &__infos {
    display: flex;
    flex-wrap: wrap;

    & > * {
      padding-right: 2rem;
    }
  }

  &__cta {
    margin-top: 1rem;
    display: flex;

    .cta {
      font-size: 1.8rem;
      padding: 1.5rem 5rem;
      font-weight: 600;

      &:after {
        content: @fa-var-play;
        font-family: 'Font awesome 5 pro';
        font-weight: 400;
        margin-left: 1rem;
      }
    }
  }

  &__player {
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 50%;
      height: 100%;
      background: -moz-linear-gradient(left, #232323 30%, rgba(35, 35, 35, 0.5) 50%, rgba(35, 35, 35, 0) 65%);
      background: -webkit-linear-gradient(left, #232323 30%, rgba(35, 35, 35, 0.5) 50%, rgba(35, 35, 35, 0) 65%);
      background: linear-gradient(to right, #232323 30%, rgba(35, 35, 35, 0.5) 50%, rgba(35, 35, 35, 0) 65%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000',endColorstr='#00000000',GradientType=1);
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      right: -5%;
      top: 0;
      width: 20%;
      height: 100%;
      background: -moz-linear-gradient(right, #232323 30%, rgba(35, 35, 35, 0.5) 50%, rgba(35, 35, 35, 0) 65%);
      background: -webkit-linear-gradient(right, #232323 30%, rgba(35, 35, 35, 0.5) 50%, rgba(35, 35, 35, 0) 65%);
      background: linear-gradient(to left, #232323 30%, rgba(35, 35, 35, 0.5) 50%, rgba(35, 35, 35, 0) 65%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000',endColorstr='#00000000',GradientType=1);
      z-index: 1;
    }
  }

  &__thumb {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 45%;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top right;
    opacity: .8;
  }

  @media screen and (max-width: 920px) {
    display: flex;
    flex-direction: column;

    &__grid {
      display: contents;
    }

    &__player {
      order: 1;
      margin: 0 -1rem;

      &:before,
      &:after {
        content: none;
      }
    }

    &__left {
      padding: 0 1rem;
      margin: 2rem 0;
      order: 2;
    }

    &__right {
      order: 1;
      position: absolute;
      top: 0;
      left: -1rem;
      width: calc(100% + 2rem);
      padding-bottom: calc(56.5%);
      z-index: 1;
    }

    &__thumb {
      padding-bottom: 56.5%;
      background-position: center;
    }

    &__title {
      font-size: 2rem;

      &:before {
        left: -1rem;
      }
    }

    &__subtitle {
      font-size: 1.5rem;
    }

    &__link {
      font-size: 1.5rem;
    }

    &__description {
      font-size: 1.5rem;
    }

    &__cta {
      .cta {
        font-size: 1.5rem;
        padding: 1rem;
      }
    }

    &__play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.tags-list {
  margin-bottom: 4rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1rem;
  }

  &__group {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: none;

    &--active {
      display: flex;
    }
  }

  &__button {
    padding: 1rem 2rem;
    color: #747474;
    font-size: 1.8rem;

    &--active {
      color: var(--main-color);
      font-weight: bold;
    }
  }

  &__cta {
    padding: 1rem 3rem;
    margin-bottom: -2rem;
  }
}
