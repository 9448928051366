.main-text {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  font-style: italic;
  color: #6d6d6d;

  h1 {
    text-align: center;
    margin-top: 2em;
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
    color: #6d6d6d
  }
}


.bottom-text {
  border-top: 1px solid rgba(255,255,255,0.1);
  padding-top: 3rem;
  margin-bottom: 20px;
  text-align: justify;
  margin-top: 2rem;
  display: block;

  h2 {
    color: var(--main-color);
    font-weight: 700;
    font-size: 1.3rem;
    margin: 0;
  }

  p {
    font-size: 1.3rem;
    color: #747474;
    font-weight: 200;
    margin: 0;
    padding: 1rem 0;

    strong {
      color: #747474;
    }

    a {
      color: var(--main-color);
    }

    a,
    strong {

      font-weight: 700;
    }
  }
}
